html {
  scroll-behavior: smooth;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #fff;
  overflow: auto;
  z-index: 1200;
  padding: 30px;
  box-shadow: 0px 0px 5px #ccc;
  max-width: 650px;
  transform: translate(-50%, -50%);
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000070;
  z-index: 1500;
}
.modal-pop-title img {
  max-width: 40px;
  margin-right: 15px;
}
.popup-wrapper .modal-pop-title {
  border-bottom: 1px solid #ccc;
  font-size: 21px;
  font-weight: normal;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.Modal2 {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #fff;
  overflow: auto;
  z-index: 1200;
  padding: 5px;
  box-shadow: 0px 0px 5px #ccc;
  max-width: 650px;
  transform: translate(-50%, -50%);
}
.Modal2 .MuiBox-root {
  padding: 10px !important;
}
.Modal2 .loginForm2 {
  background: #fff;
  max-width: 100%;
  width: 450px;
  margin: auto;
  padding: 10px 10px 30px 10px;
  box-shadow: none;
}
.Modal2 .loginForm2 p + p {
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: normal;
  color: #333;
}
.Modal2 .closeBtn {
  position: absolute !important;
  z-index: 1000;
  bottom: 10px;
  right: 10px;
}
.Modal2 .popup2 {
  max-height: 480px;
  padding: 0px;
}
.Modal2 .popup-wrapper {
  padding-bottom: 50px;
}
.popup-wrapper button {
  float: right;
  position: relative;
}

$softorange: #ccc;
$tomatored: #f25c66;
$mediumblu: #1e272d;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap");
ol,
ul,
dl,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
}
img {
  max-width: 100%;
}
body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 28px;
  background: #fff;
  color: #333333;
}
.wrapperOuter {
  max-width: 1600px;
  margin: auto;
  background: #fff;
  box-shadow: 0 0 3px #ccc;
}
.wrapper {
  max-width: 1280px;
  margin: auto;
  font-size: 16px;
  text-align: left;
  color: #fff;
}
.header .topRow span {
  margin-left: 15px;
}
.fontIcon {
  margin-right: 10px;
}
.topRow {
  padding: 15px 0px;
}
.header a {
  color: #fff;
}
.header {
  background: #05376f;
}
.header ul li {
  display: inline-block;
  padding: 10px 15px;
}
.header ul li a {
  display: block;
  padding: 5px;
  border-bottom: 5px solid #446993;
  text-decoration: none;
  color: #fff !important;
}
.header ul li a .MuiButton-textPrimary {
  color: #fff;
  text-transform: capitalize;
}
.header ul li a:hover,
.header ul li .active {
  border-bottom: 5px solid#076d37;
}
.AboutHome {
  background: url(../images/HeaderBottom.png) center top no-repeat;
  background-size: cover !important;
  padding: 50px 0px;
}

.AboutHome h2 {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 15px;
}
.AboutHome p {
  line-height: 26px;
}
.AboutHome h4 {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 0px;
  margin: 0px;
}
.AboutHome h4 + p {
  line-height: 26px;
  margin-bottom: 20px;
}
.coursesComp {
  padding: 25px 0px;
}
.coursesComp h2 {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 15px;
}
.coursesComp .inner {
  background: #d7e7f9;
  padding: 10px;
  height: 100%;
}
.coursesComp .inner .image {
  height: 250px;
  background-size: cover !important;
}
.coursesComp .inner img {
  max-width: 100%;
  max-height: 250px;
}
.coursesComp .inner a {
  margin: 0px;
  padding: 0px;
}
.coursesComp .inner a button {
  display: block;
  text-align: center;
  width: 100%;
}
.coursesComp .inner h3 {
  margin: 0px;
  padding: 0px;
}
.FooterMenu {
  background: #05376f;
  padding: 25px 0px;
  text-align: center;
}
.FooterMenu ul {
}
.FooterMenu ul li {
  display: inline-block;
}
.FooterMenu ul li a {
  display: inline-block;
  padding: 15px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}
.socialMenu {
  background: #1e5a9d;
  padding: 15px 0px;
  text-align: center;
  color: #fff;
  font-size: 18px;
}
.socialMenu ul {
  display: inline-block;
  line-height: 35px;
}
.socialMenu ul li {
  display: inline-block;
}
.socialMenu ul li a {
  display: inline-block;
  padding: 0px 15px;
  color: #164171;
  text-transform: uppercase;
  font-weight: bold;
}
.socialMenu ul li .MuiButton-label {
  color: #fff !important;
}

.footer {
  background: #05376f;
  padding: 50px 0px;
}
p.copyRight {
  line-height: 65px;
  color: #fff;
  margin-bottom: 0px;
}

.blog_resource {
  background: #d7e7f9;
  padding: 50px 0px;
}
.blog_resource h2 {
  font-weight: bold;
  font-size: 25px;
  color: #000;
  padding-bottom: 10px;
}
.blog_resource h2 a {
  color: #000;
  font-weight: bold;
  font-size: 25px;
}
.blog_resource .blog {
  border-left: 5px solid #05376f;
  background: #fff;
  padding: 25px;
  height: 100%;
}
.blog_resource .blog h2 {
  padding-bottom: 15px;
  margin: 0px;
}
.blog_resource p {
  padding-bottom: 10px;
  margin: 0px;
}
.blog_resource a {
  color: #000;
}

.testimonials {
  padding: 50px 0px;
}
.testimonials h3 {
  font-weight: bold;
  margin: 0px;
  font-size: 20px;
  color: #000;
  padding-bottom: 10px;
}
h2 {
  font-weight: bold;
  font-size: 25px;
  color: #000;
  padding-bottom: 20px;
}
.testimonials .textWrapper {
  padding: 0px 80px 0px 310px;
}
.testimonials .textWrapper .image {
  position: absolute;
  left: 100px;
  top: 0px;
  width: 200px;
  border-radius: 150px;
  margin-top: 15px;
}
.testimonials .textWrapper .image img {
  border: 2px solid #f5f5f5;
  box-shadow: 0px 0px 5px #ccc;
}
.slick-arrow {
  width: 72px;
  height: 190px;
}
.slick-next:before {
  background: url(../images/arrowNext.jpg) right center no-repeat;
  width: 72px;
  height: 190px;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 5;
}

.slick-prev:before {
  background: url(../images/arrowBack.jpg) right center no-repeat;
  width: 72px;
  height: 190px;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 5;
}

.slick-arrow {
  width: 72px;
  height: 190px;
  position: absolute;
  top: 112px;
}

.subHeader {
  background: #446993;
  padding: 35px 0px;
}
.subHeader h1 {
  font-weight: bold;
  margin: 0px;
  font-size: 25px;
  color: #fff;
}
.courseDetails {
  padding: 35px 0px;
  line-height: 26px;
  font-size: 16px;
}
.courseDetails h2 {
  font-size: 40px;
  font-weight: bold;
  padding-bottom: 20px;
  margin: 0px;
}
.courseDetails h3 {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 20px;
  margin: 0px;
}
.courseDetails h5 {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  margin: 0px;
}
.courseDetails .desc {
  margin-bottom: 20px;
}
.cursorPointer {
  cursor: pointer;
}
.courseDetails p {
  font-size: 16px;
  line-height: 26px;
}
.courseDetails ul {
  padding-left: 20px;
  margin-bottom: 20px;
}
.courseDetails ol {
  padding-left: 20px;
  margin-bottom: 20px;
}

.courseDetails ul li {
  padding-bottom: 10px;
}
.courseDetails ol li {
  padding-bottom: 10px;
}

.courseDetails button {
  border: 1px solid #446993 !important;
  background: #fff;
  color: #333333;
  padding: 8px 15px;
  line-height: normal;
  font-size: 18px;
  cursor: pointer !important;
}
.courseDetails button img {
  margin-right: 15px;
}
.courseDetails .rightPanel .inner {
  padding: 15px;
  background: #bddafa;
  border: 2px solid #446993;
  border-radius: 15px;
}
.courseDetails .rightPanel button {
  background: #076d37;
  color: #fff;
}
.courseDetails table {
  width: 100% !important;
  margin-bottom: 15px;
}
.courseDetails table p {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.courseDetails table tr {
}
.courseDetails table tr td {
  padding: 10px;
}

.loginForm2 {
  background: #fff;
  max-width: 550px;
  width: 100%;
  margin: auto;
  padding: 50px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.loginForm2 p {
  font-size: 20px;
  font-weight: bold;
  color: #05376f;
}
.loginForm2 p + p {
  padding-bottom: 25px;
  font-size: 16px;
  font-weight: normal;
  color: #333;
}
.loginForm2 img {
  max-height: 100px;
}

.loginForm2 .MuiOutlinedInput-root {
  width: 100%;
  text-align: left;
}
.MuiFormControl-root {
  width: 100%;
}
.smalltext {
  font-size: 14px;
}

.divider {
  position: relative;
  margin-bottom: 15px;
  text-align: center;
}
.divider span {
  background: #fff;
  position: relative;
  z-index: 2;
  padding: 0px 10px;
}
.divider::before {
  border-top: 1px solid #05376f;
  content: "";
  width: 100%;
  left: 0px;
  position: absolute;
  top: 12px;
}
.hide-on-popup {
  text-align: center;
}
.MuiButton-containedPrimary {
  background-color: #076d37 !important;
  border: 1px solid #076d37 !important;
  color: #fff;
}
.MuiButton-outlinedPrimary {
  border: 1px solid #05376f !important;
  color: #05376f !important;
}

.testimonialsWrapper {
  border: 1px solid #ccc;
  border-radius: 30px;
  padding: 20px;
  margin-bottom: 30px;
}
.testimonialsWrapper h4 {
  padding-bottom: 10px;
  color: #05376f;
  font-weight: bold;
  font-size: 21px;
}
.testimonialsWrapper h3 {
  padding: 20px 0px 0px 0px;
}
.testimonialsWrapper .textWrapper {
  padding-top: 0px;
  float: left;
}
.testimonialsWrapper .image {
  text-align: center;
}
.testimonialsWrapper .image img {
  border-radius: 100%;
  box-shadow: 0px 0px 5px #ccc;
  width: 150px;
  height: 150px;
}
.testimonialsPage .row .elementGab:nth-child(odd) .testimonialsWrapper {
  background: #4469931c;
}

#dashboard-courses {
  background: #fff;
  padding: 20px 0px;
}
#dashboard-courses .coursesComp {
  margin-top: 0px;
  margin: 0px;
}
.section.aboutus {
  padding: 50px 0px;
}
.section.aboutus p:last-child {
  padding: 0px;
  margin: 0px;
}
.section {
  padding: 0 0 70px;
}
.innerpadding {
  padding: 50px 100px;
}
.mission-vision {
  background: #05376f3b;
  padding: 0px;
  // margin-bottom: 70px;
}
.mission-vision ul {
  margin: 0px 0px 20px 20px;
}
.mission-vision li {
  margin: 0px;
  color: #555;
  line-height: 26px;
  text-align: left;
  padding-bottom: 15px;
}
.mission-vision h5 {
  font-weight: normal;
  line-height: 36px;
}
.mission-vision h3 {
  line-height: 36px;
  text-align: left;
  margin: 0px 0px 20px 0px;
}

h3 {
  font-weight: bold;
  font-size: 20px;
  color: #000;
  padding-bottom: 20px;
}

h4 {
  font-weight: bold;
  font-size: 17px;
  color: #000;
  padding-bottom: 20px;
}
.USPComp {
  max-width: 470px;
  margin: auto;
}
.cursorPointer {
  cursor: pointer;
}
.USPComp .cursorPointer {
  padding: 15px 15px;
  border-radius: 15px;
}
.USPComp .cursorPointer:hover {
  background: #f5f5f5;
  box-shadow: 0px 0px 5px #ccc;
}
.USPComp .cursorPointer p {
  line-height: 20px;
  margin-bottom: 0px;
}
.blog_resource p {
  margin: 0px;
  padding: 0px;
}
.blog_resource p a {
  display: block;
  padding: 10px;
}
.blog_resource p a:hover {
  background: #05376f;
  color: #fff;
  padding: 10px;
}
p.paraDuration,
p.fees {
  font-size: 22px;
  font-weight: bold;
  line-height: 32px;
}
.careerDetails {
  background: url(../images/Career.jpg) center top no-repeat;
}
.innerForm {
  background: #bddafa;
  padding: 25px;
  border-radius: 15px;
}
.MuiTextField-root,
.react-datepicker-wrapper,
.react-datepicker-wrapper input,
.react-tel-input .form-control {
  background: #fff;
  max-width: 100%;
  width: 100%;
  min-height: 40px;
  border: none !important;
}
.careerDetails .short_description h1 {
  font-size: 40px;
  padding-bottom: 40px;
}
.careerDetails .short_description {
  color: #fff;
  padding: 100px 0px;
  // height: 400px;
}
.wrapperContent {
  padding: 50px 0px;
}
.freeVideo .inner {
  background: #f5f5f5;
  padding: 15px;
  // margin-bottom: 30px;
  height: 100%;
}
.freeVideo .inner h3 {
  margin: 0px;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
}
.bannerBlog {
  background: url(../images/blogBanner.jpg) center top no-repeat;
  background-size: cover !important;
  padding: 100px 0px;
  text-align: center;
}
.bannerBlog .inner {
  color: #fff;
  max-width: 850px;
  margin: auto;
}
.bannerBlog .inner h2 {
  color: #fff;
  font-size: 40px;
  margin-bottom: 15px;
  padding: 0px;
}
.blogList {
  background: #b6d5f8;
  box-shadow: 0px 0px 10px #ccc;
  border-radius: 10px;
}
.blogList .inner {
  padding: 15px;
}
.blogList .inner h2 {
  font-size: 18px;
  font-weight: bold;
}
.blogList .inner .nav-link {
  padding: 0px;
  margin: 0px;
}
.blogList img {
  border-radius: 10px 0 0 10px;
}
.blog-sidebar {
  padding-left: 20px;
}
.blog-sidebar .item {
  padding: 15px 0px;
}
.blog-sidebar .item ul.cat-list {
  margin: 0px;
  padding: 0px;
}
.blog-sidebar .item ul.cat-list li {
  display: block;
  padding: 0px 0px;
  border-bottom: 1px solid #ccc;
}
.blog-sidebar .item h5 {
  margin-bottom: 10px;
  color: #05376f;
}
.blog-sidebar .item ul.cat-list li:last-child {
  padding-bottom: 0px;
}
.blog-sidebar .item ul li button {
  display: block;
  width: 100%;
  padding: 10px;
}
.blog-sidebar .item ul.cat-list li .MuiButton-label {
  color: #333;
  text-transform: initial;
}
.blog-sidebar .item .nav-link {
  margin: 0px;
  padding: 0px;
}
.blog-sidebar .item button {
  text-align: left;
}

.description p {
  padding-bottom: 15px;
  margin: 0px;
}
.description h1 {
  padding-bottom: 15px;
}
.description h1 {
  font-size: 23px;
  line-height: 42px;
  padding-bottom: 15px;
  margin: 0px;
}
.description h2 {
  font-size: 22px;
  line-height: 39px;
  padding-bottom: 15px;
  margin: 0px;
}
.description h3 {
  font-size: 21px;
  line-height: 36px;
  padding-bottom: 15px;
  margin: 0px;
}
.description h4 {
  font-size: 20px;
  line-height: 33px;
  padding-bottom: 15px;
  margin: 0px;
}
.description h5 {
  font-size: 19px;
  line-height: 30px;
  padding-bottom: 15px;
  margin: 0px;
}
.description p {
  font-size: 16px;
  line-height: 26px;
}
.description ul {
  padding-left: 20px;
  margin-bottom: 20px;
}
.description ol {
  padding-left: 20px;
  margin-bottom: 20px;
}

.description ul li {
  padding-bottom: 10px;
}
.description ol li {
  padding-bottom: 10px;
}

.add-response {
  padding: 35px;
  max-width: 600px;
  background: #f5f5f5;
  margin-bottom: 50px;
}
.comments-list {
  padding: 20px 0 0 0;
  border-top: 1px solid #ccc;
  margin-top: 10px;
}
.comments-list .list .item {
  margin-bottom: 30px;
  width: 90%;
}
.comments-list .list .item:nth-child(even) {
  margin-left: 10%;
}
.comments-list .list .item .comment-box {
  background: #d7e7f9;
  padding: 25px;
  border: 1px solid #ccc;
  border-radius: 10px;
  line-height: 28px;
  font-size: 16px;
}
.comments-list .list .item:nth-child(even) .comment-box {
  background: #f5f5f5;
  border: 1px solid #ccc;
}
.comments-list .list .item span {
  font-size: 13px;
  margin-left: 5px;
}
.add-response h4 {
  padding-bottom: 25px;
  font-size: 18px;
  font-weight: bold;
}
.add-response p {
  padding-bottom: 25px;
}
.add-response p:last-child {
  padding-bottom: 0px;
}

.like {
  position: fixed;
  top: 50%;
  box-shadow: 0px 0px 5px #ccc;
  transform: translate(-50% 0px);
  z-index: 1000;
  width: 70px;
  left: 10px;
  background: #d7e7f9;
  border-radius: 10px;
  padding: 10px 5px;
}
.like ul {
  margin: 0px;
}
.like ul li {
  display: block;
  padding: 0px 0px 10px 0px;
  margin-bottom: 10px;
  border-bottom: 1px solid #00000020;
}
.like ul li:last-child {
  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.like ul li .MuiSvgIcon-root {
  margin-left: 7px;
}

.colorWhite {
  color: #fff;
}

.USPComp h4 {
  margin: 0px;
  padding-bottom: 10px;
}

.contact_info {
  padding: 25px;
  background: #05376f3b;
}
.contact_info h3 {
  padding-bottom: 30px;
}
.contact_info span {
  font-weight: bold;
  font-size: 20px;
  position: relative;
  top: 4px;
}
.react-tel-input {
  border: 1px solid #ccc;
  margin-bottom: 20px;
}
.nav-link {
  padding: 0px;
  margin: 0px;
}
// Student Header
.student-header {
  background: #446993;
  padding: 35px 0px;
  border-bottom: 1px solid #05376f;
  border-top: 1px solid #05376f;
  padding: 10px;
  margin-bottom: 30px;
  text-align: center;
}
.header-menu-admin ul {
  display: block;
  margin: 0px;
  padding: 0px;
}
.header-menu-admin ul li {
  float: left;
  display: block;
  position: relative;
}
.header-menu-admin > ul > li > a button {
  padding: 5px 20px;
  width: 100%;
  color: #fff;
}
.header-menu-admin ul li ul {
  position: absolute;
  left: 0px;
  top: 100%;
  display: none;
  background: #fff;
  padding: 15px;
  width: 200px;
  box-shadow: 0px 0px 5px #ccc;
  z-index: 1000;
}
.header-menu-admin ul li li {
  display: block;
  float: none;
  text-align: left;
}
.header-menu-admin ul li:hover ul {
  display: block;
}
.header-menu-admin ul li button {
  width: 100%;
  text-align: left;
}
.header-menu-admin ul li a.navbar-active .MuiButton-label {
  color: #fff;
}
.header-menu-admin .MuiButton-label {
  justify-content: left;
}
.student-dashboard .MuiPaper-root {
  box-shadow: none !important;
}
.student-dashboard {
  padding-bottom: 50px;
}
.student-dashboard .MuiTypography-h6 {
  text-align: left;
}
.student-dashboard .MuiPaper-root {
  box-shadow: none !important;
}
.student-dashboard .MuiTable-root {
  border: 1px solid #e0e0e0;
}
.student-dashboard .MuiTable-root thead th {
  background: #446993;
  padding-top: 13px !important;
  padding-bottom: 10px !important;
  color: #fff !important;
}
.student-dashboard .MuiTable-root thead th * {
  color: #fff !important;
}
.student-dashboard .short_desc {
  padding-bottom: 15px;
  font-size: 14px;
  line-height: 21px;
}
.card-header h3 {
  margin: 0px;
  padding: 0px;
}
.card-header.bg-secondary {
  background: #05376f !important;
}
.student-dashboard .card-body .table.table-bordered {
  margin: 0px;
}
.student-dashboard .card-body .table.table-bordered,
.student-dashboard .card-body .table-bordered th,
.student-dashboard .card-body .table-bordered td {
  border: none !important;
}
.student-dashboard .card-header .MuiButton-root {
  background: #05376f !important;
  background-color: #05376f !important;
  box-shadow: none !important;
  color: #fff !important;
  border: none !important;
}
.student-dashboard .card-body .font-weight-bold {
  font-weight: bold;
  font-size: 18px;
  color: #05376f;
}
.student-dashboard .card-body .table .thead-light th {
  background: #abcbef !important;
  font-size: 20px;
  font-weight: bold;
  color: #05376f;
}
.student-dashboard .card-body .table tr:nth-child(even) {
  background: #d4e5f9 !important;
}
.student-dashboard .card-body {
  background: #abcbef;
}
.card-header.bg-secondary .nav-link {
  float: right;
  background: #fff;
  border-radius: 5px;
}
.table-bordered th,
.table-bordered td {
  font-size: 14px;
}
.card-header h3 {
  color: #fff;
  line-height: 35px;
}
.FreeResource h3 {
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
}

.logoutBtn {
  background: #fff !important;
}

.add-response h4 svg {
  margin-right: 8px;
}
.section.aboutus p {
  padding-bottom: 15px;
}
.section.aboutus ul {
  padding-left: 20px;
  padding-bottom: 8px;
}
.section.aboutus ul li {
  padding-bottom: 8px;
}
.mainMenu ul ul {
  position: absolute;
  display: none;
  width: 250px;
  z-index: 100;
  background: #05376f;
  // box-shadow: 0px 0px 5px #f5f5f5;
  text-align: left;
}
.mainMenu ul li:hover ul {
  position: absolute;
  display: block;
}
.mainMenu ul li ul li {
  display: block;
  width: 100%;
  padding: 0px 15px;
}
.mainMenu ul li ul li a {
  text-transform: capitalize;
  padding: 10px;
  text-align: left;
  margin: 0px;
  line-height: 22px;
  font-size: 15px;
  border-bottom: 0px !important;
}

.mainMenu ul li ul li a .MuiButton-label {
  text-align: left;
}

.coursesComp .inner a button.MuiButton-textPrimary {
  color: #333;
}

.header-menu-admin .icon {
  margin-right: 15px;
}
.student-dashboard .MuiTable-root thead th {
  background: #05376f !important;
}
div[class^="MUIDataTableHeadCell"] {
  white-space: nowrap;
}
.student-dashboard .MuiTable-root thead th {
  width: 150px;
}
.student-dashboard .blogItem.innerbox {
  background: #d7e7f9;
  padding: 15px;
  height: 100%;
}
.student-dashboard .blogItem.innerbox h3 {
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  line-height: 24px;
}
.student-dashboard .blogItem.innerbox .textWrapper {
  padding-top: 15px;
}
.MuiButton-textPrimary {
  color: #333;
}

.student-dashboard ul.list li {
  display: block;
  list-style: none;
}
.student-dashboard ul.list li a {
  color: #333;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  display: block;
  font-weight: normal;
  line-height: 24px;
  font-size: 14px;
}
.student-dashboard ul.list li a.active {
  background: #446993;
  color: #fff;
}
.student-dashboard .MuiBox-root {
  padding: 0px;
  box-shadow: 0px 0px 5px #ccc;
  padding: 10px 20px;
}
.student-dashboard .MuiAccordionSummary-root {
  padding: 0px;
}
.student-dashboard .MuiAccordionSummary-root.Mui-expanded {
  min-height: 30px;
}
.student-dashboard .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px;
}
.student-dashboard .MuiAccordionDetails-root {
  margin: 0px;
  padding: 0px;
  display: inline !important;
}
.student-dashboard .MuiAccordionDetails-root p {
  padding: 0px;
  margin: 0px;
}
.student-dashboard .MuiAccordionDetails-root .view-details.nav-link {
  float: right;
}
.student-dashboard .info {
  padding: 10px 15px;
  border: 1px solid #ccc;
  background: #44699310;
  font-weight: bold;
}
.student-dashboard .MuiButton-textPrimary,
.backtoBtn {
  color: #05376f !important;
}
.aboutTrainers p {
  margin: 0px;
}
.aboutTrainers .inner {
  background: #d7e7f9;
  padding: 10px;
  height: 100%;
}
.courseBottom {
  padding-top: 15px;
}
.courseBottom h3 {
  font-size: 20px;
}
.courseDetails ul li:last-child {
  margin: 0px;
  padding: 0px;
}
iframe {
  width: 100%;
  min-height: 450px;
}
.courseDetails .rightPanel button.Mui-disabled {
  background: #cccccc !important;
  border-color: #ccc !important;
}
.topRow {
  text-align: right;
}
.hangerMenuClose {
  display: none;
}

.mainMenu ul li ul li {
  border-bottom: 1px solid #e3e5e745 !important;
  padding: 0px;
}
.mainMenu ul li ul li a {
  padding: 10px 20px;
}
.mainMenu ul li ul li a:hover {
  background: #e3e5e745;
}
.courseDetails button.MuiButton-containedPrimary {
  background: #083971 !important;
  color: #fff !important;
}
.hangerMenuOuter {
  position: absolute;
  z-index: 100001;
  top: 10px;
  right: 5px;
}
.hangerMenuOpen button,
.hangerMenuClose button {
  border: 1px solid #fff !important;
}
.hangerMenuOpen,
.hangerMenuClose {
  float: right;
  margin-right: 15px;
}
.hangerMenuOpen .MuiButtonBase-root,
.hangerMenuClose .MuiButtonBase-root {
  padding: 0px;
  margin: 0px;
}
.hangerMenuOpen .MuiButton-label,
.hangerMenuClose .MuiButton-label {
  padding: 10px 10px 10px 10px;
  width: 50px;
  display: block;
}
.hangerMenuOpen .MuiButton-label span,
.hangerMenuClose .MuiButton-label span {
  border-bottom: 2px solid #fff;
  margin-bottom: 5px;
  display: block;
  margin-bottom: 10px;
}
.hangerMenuClose .MuiButton-label span:last-child,
.hangerMenuOpen .MuiButton-label span:last-child {
  margin: 0px !important;
}

.search-results {
  position: absolute;
  left: 0px;
  top: 80px;
  background: #fff;
  box-shadow: 0px 5px 5px #ccc;
  padding: 15px;
  margin: 0px 15px;
  z-index: 100;
}
.search-results ul li {
  display: block;
}
.search-results .MuiButton-label {
  color: #333 !important;
}
.close-container {
  position: relative;
  margin: auto;
  width: 30px;
  height: 30px;
  cursor: pointer;
  right: 10px;
}

.leftright {
  height: 4px;
  width: 30px;
  position: absolute;
  margin-top: 24px;
  background-color: $softorange;
  border-radius: 2px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in;
}

.rightleft {
  height: 4px;
  width: 30px;
  position: absolute;
  margin-top: 24px;
  background-color: $softorange;
  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in;
}
.backToTop {
  background: url(../images/top.png) no-repeat center center;
  width: 40px;
  height: 40px;
  text-indent: -100000px;
  position: fixed;
  bottom: 100px;
  right: 100px;
  border-radius: 100%;
  background-size: 40px 40px;
}
.text-underline {
  text-decoration: underline !important;
}
.hangerMenuOuter {
  display: none;
}
.desktopVersion {
  display: block;
}
.mobileVersion {
  display: none;
}
@media (max-width: 990px) {
  .desktopVersion {
    display: none;
  }
  .mobileVersion {
    display: block;
  }
  .hangerMenuOuter {
    display: block;
  }
  .login_box {
    padding-bottom: 10px;
  }
  .topRow {
    text-align: center;
  }
  .site-logo {
    max-width: 280px;
    display: block;
    float: left;
  }
  .topMenu {
    text-align: center;
  }
  .header .topMenu ul li {
    display: inline-block;
    padding: 0px;
    margin-right: 15px;
  }
  .login_box {
    text-align: center;
  }

  .mainMenu {
    position: absolute;
    right: 0px;
    top: 0px;
    background: #05376f;
    z-index: 1001;
    width: 100%;
    padding: 50px 10px 10px 10px;
  }
  .overLayer {
    background: #00000090;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    width: 100%;
  }
  .mainMenu ul li {
    display: block;
    float: none;
  }
  .USPComp {
    text-align: center;
  }
  .USPComp .cursorPointer p {
    margin-bottom: 15px;
  }
  .testimonials .textWrapper {
    padding: 10px;
  }
  .testimonials .textWrapper .image {
    position: relative;
    left: 80px;
    top: 0px;
    width: 200px;
    border-radius: 150px;
    margin-top: 15px;
  }
  p.copyRight {
    line-height: 25px;
  }
  .footer {
    padding: 20px 0px;
  }
  .FooterMenu ul li {
    display: block;
    text-align: left;
  }
  .FooterMenu ul li a {
    display: block;
    padding: 10px;
    border-bottom: 1px solid #1b497c;
  }
  .socialMenu ul {
    display: block;
  }
  .courseDetails button {
    display: block;
    float: none;
    width: 100%;
    margin-bottom: 10px;
  }
  #root {
    overflow: hidden;
  }
  .testimonialsWrapper .image {
    margin-bottom: 20px;
  }
  .mainMenu ul li ul {
    display: block !important;
    width: 100%;
    box-shadow: none;
    position: relative !important;
    text-align: left;
  }
  .header ul li {
    padding: 0px;
  }
  .header ul li a {
    text-align: left;
  }
  .mainMenu ul li ul li {
    padding: 0px;
  }
  .mainMenu ul li ul li a {
    border-bottom: 0px !important;
    display: block;
    padding-left: 25px;
  }
  .user_name {
    text-align: center;
    margin-bottom: 10px;
  }
  .Modal {
    width: 96%;
    height: 96%;
    overflow: scroll;
  }
  .hangerMenuClose {
    display: block;
  }

  .header ul li a {
    border-bottom: 0px;
  }
  .header ul li a {
    border-bottom: 1px solid #e3e5e745 !important;
  }
  .backToTop {
    bottom: 10px;
    right: 10px;
    border: 2px solid #ccc;
  }
}
.mainMenu .bigBtn {
  font-size: 26px !important;
  font-weight: bold;
  position: relative;
  padding-left: 10px;
  float: right;
}

h3 span {
  display: block;
  font-size: 14px !important;
  padding: 10px 0px;
}
.MuiButton-containedSecondary:hover {
  background-color: #bddafa !important;
}

.loader {
  position: relative;
  display: flex;
}
.loader:before,
.loader:after {
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  margin: 0 5px;
  border-radius: 50%;
  color: #fff;
  background: currentColor;
  box-shadow:
    50px 0,
    -50px 0;
  animation: left 1s infinite ease-in-out;
}
.loader:after {
  color: #ff3d00;
  animation: right 1.1s infinite ease-in-out;
}

@keyframes right {
  0%,
  100% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
}

@keyframes left {
  0%,
  100% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-10px);
  }
}
.loaerOuter {
  max-width: 260px;
  margin: auto;
  padding: 25px 100px;
  margin: 50px auto;
  background: #f5f5f5;
  text-align: center;
  justify-content: center;
}

#note-logo {
  position: relative;
}
.waterMark {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
}

img {
  -webkit-user-drag: none;
  user-drag: none;
}
.list {
}
.list .item {
  border-bottom: 0px solid #ccc;
  cursor: pointer;
  box-shadow: 0px 0px 5px #ccc;
  margin-bottom: 10px;
}
.item_list {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
}

.active.item_list {
  background: #446993;
  color: #fff;
}
.item_des {
  padding: 16px;
}

.item_des .inner {
  padding: 15px;
  background: #f5f5f590;
  box-shadow: 0px 0px 5px #cccccc90;
}

.description table,
.description .quill-better-table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  width: 100%;
}

.description table tr th,
.description .quill-better-table tr th {
  border: 1px solid #ccc;
  border-collapse: collapse;
  padding: 5px;
}

.description table tr td,
.description .quill-better-table tr td {
  border: 1px solid #ccc;
  border-collapse: collapse;
  padding: 5px;
}

.description table tr td p,
.description .quill-better-table tr td p {
  padding: 0px;
  margin: 0px;
}

.description ol .ql-ui {
  position: absolute;
}
.description ol li {
  list-style-type: none;
}
.description ol li::marker {
  display: none !important;
}
.description ol li > .ql-ui:before {
  content: "";
  display: inline-block;
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
  white-space: nowrap;
  width: 1.2em;
}
.description ol li[data-list="bullet"] .ql-ui:before {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  content: "";
}
.description ol li[data-list="bullet"].ql-indent-1 .ql-ui:before {
  display: list-item;
  list-style-type: square;
  list-style-position: inside;
  content: "";
}
.description ol li[data-list="bullet"].ql-indent-1 {
  padding-left: 10px;
}
.description ol li[data-list="bullet"].ql-indent-2 {
  padding-left: 20px;
}
.description ol li[data-list="bullet"].ql-indent-3 {
  padding-left: 30px;
}
.description ol li[data-list="bullet"].ql-indent-4 {
  padding-left: 40px;
}
.description ol li[data-list="bullet"].ql-indent-5 {
  padding-left: 50px;
}
.description ol li[data-list="bullet"].ql-indent-2 .ql-ui:before {
  display: list-item;
  list-style-type: circle;
  list-style-position: inside;
  content: "";
}

.description ol li[data-list="bullet"].ql-indent-3 .ql-ui:before {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  content: "";
}
// ordered

.description ol li[data-list="ordered"] .ql-ui:before {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  content: "";
}
.description ol li[data-list="ordered"].ql-indent-1 .ql-ui:before {
  display: list-item;
  list-style-type: square;
  list-style-position: inside;
  content: "";
}
.description ol li[data-list="ordered"].ql-indent-1 {
  padding-left: 10px;
}
.description ol li[data-list="ordered"].ql-indent-2 {
  padding-left: 20px;
}
.description ol li[data-list="ordered"].ql-indent-3 {
  padding-left: 30px;
}
.description ol li[data-list="ordered"].ql-indent-4 {
  padding-left: 40px;
}
.description ol li[data-list="ordered"].ql-indent-5 {
  padding-left: 50px;
}
.description ol li[data-list="ordered"].ql-indent-2 .ql-ui:before {
  display: list-item;
  list-style-type: circle;
  list-style-position: inside;
  content: "";
}

.description ol li[data-list="ordered"].ql-indent-3 .ql-ui:before {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  content: "";
}
.description ol li.ql-indent-1 {
  margin-left: 15px;
}
.description ol li.ql-indent-2 {
  margin-left: 30px;
}
.description ol li.ql-indent-3 {
  margin-left: 45px;
}
